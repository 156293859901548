export default {
  SET_LOGOURL({ commit }, url) {
    commit('SET_LOGOURL', url)
  },
  SET_NAME({ commit }, name) {
    commit('SET_NAME', name)
  },
  SET_ADDRESS({ commit }, address) {
    commit('SET_ADDRESS', address)
  },
  SET_COMPANYNAME({ commit }, companyName) {
    commit('SET_COMPANYNAME', companyName)
  },
  SET_HEADERCOLOR({ commit }, color) {
    commit('SET_HEADERCOLOR', color)
  },
  SET_EMAIL({ commit }, supportEmail) {
    commit('SET_EMAIL', supportEmail)
  },
  SET_COMPANYADDED({ commit }, hasCompany) {
    commit('SET_COMPANYADDED', hasCompany)
  },
  SET_IDSTATUS({commit},idStatus){
    commit('SET_IDSTATUS',idStatus)
  },
  SET_LINKCODE({commit},linkCode){
    commit('SET_LINKCODE',linkCode)
  },
  SET_ROLE({commit},role){
    commit('SET_ROLE',role)
  },
  SET_BG_COLOR({commit},backgroundColor){
    commit('SET_BG_COLOR',backgroundColor)
  },
  SET_TEXT_COLOR({commit},textColor){
    commit('SET_TEXT_COLOR',textColor)
  },
  SET_CTA_COLOR({commit},ctaColor){
    commit('SET_CTA_COLOR',ctaColor)
  },
  SET_CUSTOMER_REF({commit},customerReference){
    commit('SET_CUSTOMER_REF',customerReference)
  },
  SET_STATEMENT({commit}, statement) {
    commit('SET_STATEMENT', statement)
  },
  SET_STATEMENT_DECLARED ({commit}, statementDeclared) {
    commit('SET_STATEMENT_DECLARED', statementDeclared)
  },
  SET_COMPANYDOMAIN({commit},domain){
    commit('SET_COMPANYDOMAIN',domain)
},
  SET_NEWCOMPANYDOMAIN({commit},NewDomain){
    commit('SET_NEWCOMPANYDOMAIN',NewDomain)
},
  SET_UUID({commit},pathUuid){
    commit('SET_UUID',pathUuid)
},
  SET_IDENTITY_LINK({commit},identityLink){
    commit('SET_IDENTITY_LINK',identityLink)
},
  SET_BUSINESS_TYPE({commit},BusinessType){
  commit('SET_BUSINESS_TYPE',BusinessType)
},
  SET_SOLE_TRADER({commit},soleTrader){
  commit('SET_SOLE_TRADER',soleTrader)
},
  SET_STATEMENT_ENABLED ({commit}, statementEnabled) {
  commit('SET_STATEMENT_ENABLED', statementEnabled)
  },
  SET_REQUIRED_ACTION({ commit }, action) {
    commit('SET_REQUIRED_ACTION', action)
  },
  SET_CUSTOM_ATTRIBUTES({ commit }, customAttributes) {
    commit('SET_CUSTOM_ATTRIBUTES', customAttributes)
  },
  SET_CUSTOM_ATTRIBUTE_VALUES({ commit }, customAttributeValues) {
    commit('SET_CUSTOM_ATTRIBUTE_VALUES', customAttributeValues)
  },
  SET_PORTAL_LIMITED_TO_ONE_COMPANY({ commit }, isPortalLimitedToOneCompany) {
    commit('SET_PORTAL_LIMITED_TO_ONE_COMPANY', isPortalLimitedToOneCompany)
  },
  SET_IS_END_USER_NOTIFICATIONS_ENABLED({ commit }, isEndUserNotificationsEnabled) {
    commit('SET_IS_END_USER_NOTIFICATIONS_ENABLED', isEndUserNotificationsEnabled)
  },
  SET_IS_OPTIONAL_MANUAL_COMPANY_NUMBER({ commit }, optionalManualCompanyNumber) {
    commit('SET_IS_OPTIONAL_MANUAL_COMPANY_NUMBER', optionalManualCompanyNumber)
  },

  async FETCH_COMPANYDETAILS({ commit }, slug) {
    const url = `company-details/${slug}`
    await this.$axios.get(url).then((response) => {
      if (response.status === 200) {
        commit('SET_LOGOURL', response.data.logo)
        commit('SET_BG_COLOR', response.data['insight-background-color'])
        commit('SET_TEXT_COLOR', response.data['insight-text-color'])
        commit('SET_CTA_COLOR', response.data['insight-cta-color'])
        commit('SET_CTA_COLOR', response.data['insight-cta-color'])
        commit('SET_EMAIL', response.data.email)
        commit('SET_STATEMENT', response.data.statement)
        commit('SET_STATEMENT_ENABLED', response.data.statementEnabled)
        commit('SET_CUSTOM_ATTRIBUTES', response.data.customAttributes)
        commit('SET_PORTAL_LIMITED_TO_ONE_COMPANY', response.data.isPortalLimitedToOneCompany)
      }
    })
  },

  RESETFIELD({ commit }, companyName) {
    commit('RESET_FIELD', companyName)
  },
}
