export const state = () => ({
    company: {
        logo:'',
        name: '',
        address: '',
        companyName: '',
        supportEmail:'',
        hasCompany:false,
        idStatus: false,
        linkCode:'',
        role:"",
        backgroundColor:"#FCFCFC",
        textColor:"#333333",
        ctaColor:"#333333",
        customerReference:'',
        statement_declared: false,
        statement: "Pease confirm that the details provided are true, accurate, and complete to your knowledge. Giving false or incomplete information may delay, reject, or void your contract.",
        statement_enabled: false,
        customAttributes: [],
        customAttributeValues: [],
        companyDomain:"",
        newCompanyDomain:"",
        Uuid:'',
        identityLink:'',
        businessType:'company',
        soleTrader:false,
        requiredCompanyActions : [
            {
            'companyLinkCode': '',
            'requires_input': []
            }
        ],
        isPortalLimitedToOneCompany: false,
        isEndUserNotificationsEnabled: false,
        optionalManualCompanyNumber: false
    }
})
